import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  .topTitle {
    color: ${props => (props.theme.title === 'light' ? '#6C7293' : '#FFFFFF')};
  }

  .cancel {
    background-color: ${props =>
      props.theme.title === 'light' ? '#FFF5F8' : '#3A2434'};

    p {
      padding: 0 8px;
      color: #d9214e !important;

      path {
        fill: #d9214e !important;
      }

      svg {
        margin-right: 10px !important;
      }
    }

    &:hover {
      background-color: #d9214e;
      transition: 0.5s;
      box-shadow: none;

      p {
        color: #e8fff3 !important;

        path {
          fill: #e8fff3 !important;
        }
      }
    }
  }
`;
