import React, { useCallback, useEffect, useRef } from 'react';
import i18n from 'i18next';
import * as Yup from 'yup';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { FormHandles } from '@unform/core';

import MD5 from 'crypto-js/md5';
import Base64 from 'crypto-js/enc-base64';

import { getCookie } from 'utils/cookies';
import getValidationErrors from 'utils/getValidationErrors';

import { useGlobalLoading } from 'hooks/LoadingContext';

import PageMode from 'components/Tools/PageMode';

import { authUserAPI, cancelProductClientAPI, editProduct } from '../Apis';

import {
  IAuthClient,
  ICancelProductsProps,
  IParamsCancelProducts,
  IParamsUpdateProduct,
  IResponseAuth,
} from '../Types';

const CancelProducts: React.FC<ICancelProductsProps> = ({
  isOpen,
  reasons,
  productsSelected,
  handleClose,
  updateProduct,
  clearProductsSelected,
}) => {
  const { t } = useTranslation();
  const { setGlobalLoading } = useGlobalLoading();
  const { enqueueSnackbar } = useSnackbar();
  const searchRef = useRef<FormHandles>(null);

  const idCliente = getCookie('@pdamodules::codigoCliente');
  const environment_type = String(process.env.REACT_APP_ENVIRONMENTTYPE);

  const translatorMsg = (msg: string, productId?: number): string => {
    const translator = i18n.t;
    return productId
      ? `${String(productId)} ${translator(msg)}`
      : `${translator(msg)}`;
  };

  const enviroment =
    environment_type !== 'desenvolvimento' && environment_type !== 'homologação'
      ? {
          urlPDA: 'https://api.pdahub.com.br/api/Usuario/SuperBid',
          urlClient:
            'https://sso.superbid.net/auth/realms/sbws/protocol/openid-connect/token',
          urlClientUpdate: 'https://external-connector.superbid.net/product/',
          clientSecret: '1ZGgHMxmaWFdAnzzChgR09T0BXJgKcBP',
        }
      : {
          urlPDA: 'https://api.stage.pdahub.com.br/api/Usuario/SuperBid',
          urlClient:
            'https://sso.stage.superbid.net/auth/realms/sbws/protocol/openid-connect/token',
          urlClientUpdate:
            'https://external-connector.stage.superbid.net/product/',
          clientSecret: 'fthGGWC80W4y8Vw7PuaEqPDIvT5Y3cbm',
        };

  const handleSubmit = async (data: IParamsCancelProducts) => {
    setGlobalLoading(true);
    try {
      const schema = Yup.object().shape({
        reason: Yup.number().required(),
        user: Yup.string().required(),
        newStatus: Yup.string().required(),
        password: Yup.string().required(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const authData: IAuthClient = {
        codigoCliente: +idCliente,
        usuario: data.user,
        senha: Base64.stringify(MD5(data.password)),
        urlApiCliente: enviroment.urlClient,
        gratType: 'client_credentials',
        clientId: 'pda-core-api',
        clientSecret: enviroment.clientSecret,
      };

      const auth = await authUserAPI(enviroment.urlPDA, authData);

      if (auth && auth.data.lider === true) {
        const responseAuthenticate: IResponseAuth = auth.data;

        const paramsUpdateData: IParamsUpdateProduct[] = productsSelected.map(
          p => {
            return {
              // statusId: filterReason(data.reason) ? '2' : '5',
              productId: p,
              statusId: '5',
              statusReasonId: data.reason,
              partnerIntegrationId: 5,
            };
          }
        );

        const statusApiCliente = await cancelProductClientAPI(
          enviroment.urlClientUpdate,
          paramsUpdateData,
          responseAuthenticate
        ).catch(() => {
          enqueueSnackbar(
            `Erro em requisição externa: The product does not have DISPONIVEL status and DIGITALIZADO status reason. Cannot update product.`,
            {
              variant: 'error',
            }
          );
        });

        if (statusApiCliente && statusApiCliente.status === 200) {
          await editProduct(productsSelected, data.newStatus).then(res => {
            if (res.data) {
              productsSelected.map(p => {
                updateProduct(p);
              });
              enqueueSnackbar(
                translatorMsg(
                  'screens.invoiceReceivement.cancelSuccessfully',
                  productsSelected.length
                ),
                {
                  variant: 'success',
                }
              );
              clearProductsSelected();
              handleClose();
            }
          });
        }
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        searchRef.current?.setErrors(errors);
        enqueueSnackbar(translatorMsg('screens.invoiceReceivement.errorForm'), {
          variant: 'warning',
        });
      }
    } finally {
      setGlobalLoading(false);
    }
  };

  const defaultValue = useCallback(() => {
    searchRef.current?.setFieldValue('newStatus', {
      label: 'Cancelar',
      value: 'CANCELED',
    });
  }, []);

  useEffect(() => {
    defaultValue();
  }, [defaultValue]);

  return (
    <>
      <PageMode
        isModal
        open={isOpen}
        title={String(t('components.confirmationModal.cancel'))}
        submitButtonIcon
        modalRefObject={searchRef}
        submitButtonTitle={String(t('screens.commom.saveButtonTitle'))}
        handleSubmit={(data: IParamsCancelProducts) => handleSubmit(data)}
        handleClose={() => handleClose()}
        inputs={[
          {
            name: 'reason',
            isRequired: true,
            label: String(t('screens.approved.reason')),
            placeholder: String(t('screens.commom.selectReason')),
            type: 'select',
            options: reasons,
            xl: 6,
            xs: 6,
            lg: 6,
          },
          {
            name: 'newStatus',
            isRequired: true,
            label: String(t('screens.receivement.status')),
            placeholder: String(t('screens.receivement.status')),
            type: 'select',
            options: [
              { label: 'Cancelar', value: 'CANCELED' },
              { label: 'Aprovado', value: 'APPROVED' },
            ],
            xl: 6,
            xs: 6,
            lg: 6,
          },
          {
            name: 'user',
            required: true,
            label: String(t('screens.commom.user')),
            placeholder: String(t('screens.commom.user')),
            type: 'text',
            isAutoComplete: false,
            xl: 6,
            xs: 6,
            lg: 6,
          },
          {
            name: 'password',
            isRequired: true,
            label: String(t('screens.commom.password')),
            placeholder: String(t('screens.commom.password')),
            type: 'password',
            isAutoComplete: false,
            xl: 6,
            xs: 6,
            lg: 6,
          },
        ]}
      />
    </>
  );
};
export default CancelProducts;
