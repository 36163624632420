import React, { useCallback, useState, useEffect, useRef } from 'react';
import i18n from 'i18next';

import { Fade } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';

import { Container, Search } from 'app/WMS/styles/styles';

import FixedHeader from 'components/Tools/Breadcrumb';
import SearchBox from 'components/Tools/Search';
import Loader from 'components/Tools/Loader';
import {
  ITypeProps,
  ICategoryProps,
  ISubcategoryProps,
} from 'features/DetailReceivement/Types';
import { getTypes } from 'features/DetailReceivement/apis';
import { FormHandles } from '@unform/core';

import {
  IProduct,
  IPaginateProductsProps,
  IPagination,
} from 'utils/interfaces/products.types';
import { SelectItem } from 'utils/toSelect';
import getValidationErrors from 'utils/getValidationErrors';

import {
  IGetProductsAPIParams,
  IPaginateProducts,
  IReasonsCancel,
} from './Types';
import { paginateProductsAPI, getReasonsCancelAPI } from './Apis/index';

import TableProducts from './TableProducts';
import CancelProducts from './CancelProducts';

const FollowProducts: React.FC = () => {
  const { t } = useTranslation();
  const searchRef = useRef<FormHandles>(null);

  const { enqueueSnackbar } = useSnackbar();
  const [valueInput, setValueInput] = useState<string>('');
  const [animate, setAnimate] = useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [showCancelProducts, setShowCancelProducts] = useState<boolean>(false);

  const [products, setProducts] = useState<IProduct[]>([]);
  const [reasons, setReasons] = useState<SelectItem[]>([]);
  const [productsSelected, setProductsSelected] = useState<string[]>([]);

  const [paginate, setPaginate] = useState<IPagination>({} as IPagination);

  const [currentSearch, setCurrentSearch] = useState<IGetProductsAPIParams>(
    {} as IGetProductsAPIParams
  );

  const [typesList, setTypesList] = useState<ITypeProps[]>();
  const [categoriesList, setCategoriesList] = useState<ICategoryProps[]>();
  const [subCategoriesList, setSubCategoriesList] =
    useState<ISubcategoryProps[]>();

  const translatorMsg = useCallback(
    (msg: string, response?: AxiosResponse): string => {
      const translator = i18n.t;
      return response
        ? `${String(response)} ${translator(msg)}`
        : `${translator(msg)}`;
    },
    []
  );

  const checkFields = useCallback(() => {
    if (
      searchRef.current?.getFieldValue('code_receipt') !== '' ||
      searchRef.current?.getFieldValue('etiquette') !== '' ||
      searchRef.current?.getFieldValue('integration_code') !== ''
    ) {
      return false;
    }
    return true;
  }, []);

  const handleGetReasons = useCallback(async () => {
    setIsLoading(true);

    await getReasonsCancelAPI()
      .then(res => {
        if (res.data.length > 0) {
          const response: IReasonsCancel[] = res.data;
          const transformeResponde: SelectItem[] = response.map(reason => {
            return {
              label: reason.description,
              value: reason.status_reason_id,
            };
          });
          setReasons(transformeResponde);
        }
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleSearchPaginate = useCallback(
    async (page: number, data: IGetProductsAPIParams) => {
      try {
        setIsLoading(true);
        setShowTable(false);
        checkFields();

        const isRequired = checkFields();

        const schema = Yup.object().shape({
          etiquette: Yup.string().nullable(),
          search: Yup.string().nullable(),
          status: isRequired === true ? Yup.string().required() : Yup.string(),
          integration_code: Yup.string().nullable(),
          code_receipt: Yup.string().nullable(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setCurrentSearch(data);

        const dataFilter: IPaginateProducts = {
          page,
          per_page: 50,
          etiquette: data.etiquette,
          search: data.search,
          type_id: data.type_id ? +data.type_id : null,
          category_id: data.category_id ? +data.category_id : null,
          sub_category_id: data.sub_category_id
            ? Number(data.sub_category_id)
            : null,
          status: data.status,
          integration_code: data.integration_code,
          code_receipt: data.code_receipt ? data.code_receipt : null,
        };

        await paginateProductsAPI(dataFilter)
          .then(response => {
            const productsResponse: IPaginateProductsProps = response.data;
            if (!productsResponse.data.length) {
              setProducts([]);
              enqueueSnackbar(
                translatorMsg('screens.receivement.resultsNotFound'),
                {
                  variant: 'warning',
                }
              );
            } else {
              setPaginate(response.data.pagination);
              setProducts(response.data.data);
              enqueueSnackbar(
                translatorMsg(
                  'screens.receivement.successFilter',
                  response.data?.pagination.total || 0
                ),
                {
                  variant: 'success',
                }
              );
            }
          })
          .catch(() => {
            setProducts([]);
          })
          .finally(() => {
            setIsLoading(false);
            setShowTable(true);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const erros = getValidationErrors(err);
          searchRef.current?.setErrors(erros);
          setIsLoading(false);
          enqueueSnackbar(
            translatorMsg('screens.receivement.followProductAlert'),
            {
              variant: 'warning',
            }
          );
        } else {
          console.error(err);
        }
      }
    },
    [checkFields, enqueueSnackbar, translatorMsg]
  );

  const getTypeOptions = () => {
    getTypes().then(response => {
      setTypesList(response.data);
    });
  };

  const updateByIntegrationCode = (integrationCode: string) => {
    setProducts(prevProducts =>
      prevProducts.map(product =>
        product.integration_code === integrationCode
          ? { ...product, status: 'CANCELED' }
          : product
      )
    );
  };

  const getCategoriesOptions = useCallback(
    (id: number) => {
      const categoryes = typesList?.find(type => type.id === id);

      setCategoriesList([]);
      setSubCategoriesList([]);
      searchRef.current?.setFieldValue('category_id', '');
      searchRef.current?.setFieldValue('subcategory', '');

      return setCategoriesList(categoryes?.categories);
    },
    [typesList]
  );

  const getSubCategoriesOptions = useCallback(
    (id: number) => {
      const subCategoryes = categoriesList?.find(
        categorye => categorye.id === id
      );

      return setSubCategoriesList(subCategoryes?.sub_categories);
    },
    [categoriesList]
  );

  const resultsInputsList = useCallback(() => {
    if (
      searchRef.current?.getFieldValue('code_receipt') !== '' ||
      searchRef.current?.getFieldValue('etiquette') !== '' ||
      searchRef.current?.getFieldValue('integration_code') !== ''
    ) {
      searchRef.current?.setFieldValue('status', '');
      searchRef.current?.setFieldValue('type_id', '');
      searchRef.current?.setFieldValue('category_id', '');
      searchRef.current?.setFieldValue('sub_category_id', '');
    } else {
      searchRef.current?.setFieldValue('status', {
        value: 'INTEGRATION',
        label: 'Integrado',
      });
    }
  }, []);

  const handleCloseProductsCancel = () => {
    clearProductsSelected();
    setShowCancelProducts(false);
  };

  const handleOpenProductsCancel = () => {
    setShowCancelProducts(true);
  };

  const handleClearOptions = () => {
    setCategoriesList([]);
    setSubCategoriesList([]);
  };

  const clearProductsSelected = () => setProductsSelected([]);

  const addUniqueProduct = (newProductSelectes: IProduct) => {
    setProductsSelected(prevProducts => {
      const uniqueProducts = new Set([
        ...prevProducts,
        newProductSelectes.integration_code,
      ]);
      return Array.from(uniqueProducts);
    });
  };

  const removeProductSelected = (productSelect: IProduct) => {
    setProductsSelected(prevProducts => {
      const uniqueProducts = prevProducts.filter(
        p => p !== productSelect.integration_code
      );
      return Array.from(uniqueProducts);
    });
  };

  const addRemoveProducts = (isAdd: boolean, newProductSelectes: IProduct) => {
    if (!isAdd) {
      addUniqueProduct(newProductSelectes);
      return;
    }
    removeProductSelected(newProductSelectes);
  };

  useEffect(() => {
    handleGetReasons();
    getTypeOptions();
  }, [handleGetReasons]);

  useEffect(() => {
    setAnimate(true);

    searchRef.current?.setFieldValue('status', {
      value: 'INTEGRATION',
      label: 'Integrado',
    });
    handleSearchPaginate(1, { status: 'INTEGRATION' });
  }, [handleSearchPaginate]);

  useEffect(() => {
    resultsInputsList();
  }, [resultsInputsList, valueInput]);

  return (
    <>
      <FixedHeader
        subTitle={String(t('screens.receivement.follow_up'))}
        title={t('screens.receivement.products')}
      />
      <Fade in={animate} timeout={1000}>
        <Container>
          <Search>
            <SearchBox
              customClearFunction={() => handleClearOptions()}
              inputs={[
                {
                  name: 'code_receipt',
                  type: 'text',
                  label: String(t('screens.publications.receiveCode')),
                  placeholder: String(t('screens.publications.receiveCode')),
                  onChange: e => setValueInput(e.target.value),
                },
                {
                  name: 'etiquette',
                  type: 'text',
                  label: String(t('screens.invoiceReceivement.etiquetteLabel')),
                  placeholder: String(
                    t('screens.invoiceReceivement.enterEtiquette')
                  ),
                  onChange: e => setValueInput(e.target.value),
                },
                {
                  name: 'integration_code',
                  type: 'text',
                  label: String(t('screens.publications.integationLabel')),
                  placeholder: String(
                    t('screens.publications.integationLabel')
                  ),
                  onChange: e => setValueInput(e.target.value),
                },
                {
                  name: 'search',
                  type: 'text',
                  label: String(t('screens.commom.description')),
                  placeholder: String(t('screens.commom.description')),
                },
                {
                  name: 'status',
                  type: 'select',
                  isRequired: checkFields(),
                  options: [
                    { label: 'Ajuste', value: 'ADJUSTMENT' },
                    { label: 'Pendente', value: 'PENDING' },
                    { label: 'Aprovado', value: 'APPROVED' },
                    { label: 'Reprovado', value: 'DISAPROVED' },
                    {
                      label: 'Aguardando Integração',
                      value: 'AWAITING_INTEGRATION',
                    },
                    { label: 'Integrado', value: 'INTEGRATION' },
                    { label: 'Devolvido', value: 'DEVOLVED' },
                    { label: 'Cancelado', value: 'CANCELED' },
                  ],
                  label: String(t('screens.commom.status')),
                  placeholder: String(t('screens.commom.status')),
                },
                {
                  name: 'type_id',
                  type: 'select',
                  options: typesList?.map(item => ({
                    label: item.name,
                    value: item.id,
                  })),
                  label: String(t('screens.invoiceReceivement.type')),
                  placeholder: String(
                    t('screens.invoiceReceivement.selectType')
                  ),
                  onChangeEvent: e => {
                    if (e !== undefined) {
                      getCategoriesOptions(e);
                    }
                  },
                },
                {
                  name: 'category_id',
                  type: 'select',
                  label: String(t('screens.invoiceReceivement.category')),
                  placeholder: String(
                    t('screens.invoiceReceivement.enterCategory')
                  ),
                  options: categoriesList?.map(item => ({
                    label: item.name,
                    value: item.id,
                  })),
                  onChangeEvent: e => {
                    if (e !== undefined) {
                      getSubCategoriesOptions(e);
                    }
                  },
                },
                {
                  name: 'sub_category_id',
                  type: 'select',
                  options: subCategoriesList?.map(item => ({
                    label: item.name,
                    value: item.id,
                  })),
                  label: String(t('screens.invoiceReceivement.subcategory')),
                  placeholder: String(
                    t('screens.invoiceReceivement.enterSubcategory')
                  ),
                },
              ]}
              searchBoxRef={searchRef}
              handleSubmit={(data: IGetProductsAPIParams) =>
                handleSearchPaginate(1, data)
              }
            />
          </Search>
          {isLoading && <Loader />}
          {showTable && (
            <TableProducts
              paginateData={paginate}
              products={products}
              currentSearch={currentSearch}
              selectedProducts={productsSelected}
              handleSearch={handleSearchPaginate}
              addRemoveProducts={addRemoveProducts}
              openCancelProps={handleOpenProductsCancel}
            />
          )}
          {showCancelProducts && (
            <CancelProducts
              reasons={reasons}
              isOpen={showCancelProducts}
              productsSelected={productsSelected}
              handleClose={handleCloseProductsCancel}
              updateProduct={updateByIntegrationCode}
              clearProductsSelected={clearProductsSelected}
            />
          )}
        </Container>
      </Fade>
    </>
  );
};

export default FollowProducts;
