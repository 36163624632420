import api from 'services/api';
import axios from 'axios';

import {
  IAuthClient,
  IGetProductsAPIParams,
  IPaginateProducts,
  IParamsUpdateProduct,
  IResponseAuth,
} from '../Types/index';

export const adjustmentProducts = (etiquete: string, userId: number) => {
  return api.post(
    `receipts/products/createOnUpdate?etiquette=${etiquete}&user_id=${userId}`
  );
};

export const getProductsAPI = (params: IGetProductsAPIParams) =>
  api.get('receipts/products/filterProducts', { params });

export const paginateProductsAPI = (params: IPaginateProducts) =>
  api.get('receipts/products/paginate', { params });

export const getReasonsCancelAPI = () => api.get('receipts/reasons');

export const authUserAPI = (url: string, params: IAuthClient) => {
  return api.patch(
    url,
    {
      ...params,
    },
    {
      headers: { error: 'Erro: Verifique login e senha!' },
    }
  );
};

export const editProduct = (integrationCodes: string[], newStatus: string) =>
  api.put(`/receipts/products/updateBulkByExternalId`, {
    externalIds: integrationCodes,
    data: { status: newStatus },
  });

export const cancelProductClientAPI = (
  url: string,
  params: IParamsUpdateProduct[],
  authData: IResponseAuth
) => {
  return axios.put(`${url}cancel`, JSON.stringify(params), {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authData.access_token}`,
    },
  });
};
